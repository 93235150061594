<template>
  <div v-if="node.parentid == store.state.radioButtonId" class="move_here tileSubtext">
    <p :class="[{ 'move_here_active': store.state.moveToSelect[0] == node.id && store.state.moveToSelect[1] == 0 }]" @click="moveLocationTo(node, 0)">MOVE HERE</p>
  </div>
  
  <div :style="{ 'margin-left': `${depth * marginAmount}px`}" class="Tree__item">
    <span v-if="!isFirstItem" :class="{ 'gorizontal-line__item-without-child': !hasChildren()?.length }"
      class="gorizontal-line" />
    <div class="Tree__item-inner"
    :style="[ (modalActive || qrModalActive) ? {'background-color': `#099F45`, 'color': '#fff', 'z-index': '16'} : null ]"
    :class="{ 'Tree__item-inner-without-child': !hasChildren()?.length && node?.databaseid?.length === 36 }">
      <span class="icon" v-if="hasChildren()?.length" @click="nodeClickedForExpand">
        <!-- <img v-if="isHome" :src="require('@/assets/open.svg')"/> -->
        <img v-if="!expanded && (modalActive || qrModalActive)" :src="require('@/assets/open_transparent_white.svg')" alt="open" />
        <img v-if="!expanded && !(modalActive || qrModalActive)" :src="require('@/assets/open_transparent.svg')" alt="open" />
        <img v-if="expanded && (modalActive || qrModalActive)" :src="require('@/assets/close_transparent_white.svg')" alt="close" />
        <img v-if="expanded && !(modalActive || qrModalActive)" :src="require('@/assets/close_transparent.svg')" alt="close" />
      </span>
      <div class="Tree__item-label-wrapper" @click="!store.state.movePlace ? nodeClicked() : null">
        <div class="Tree__item-label-inner">
          <span v-if="node?.machinekb == 0 || node?.databaseid?.length !== 36" class="Tree__item-label functional">{{ node?.name }}</span>
          <span v-else class="Tree__item-label functional">{{ node?.name }}</span>
          <span class="icon" v-if="node?.icon && node?.databaseid.length === 36 && !store.state.movePlace">
            <img v-if="(modalActive || qrModalActive)"  :src="require(`@/assets/${node?.icon+`_white`}.svg`)" alt="logo" />
            <img v-if="!(modalActive || qrModalActive)"  :src="require(`@/assets/${node?.icon}.svg`)" alt="logo" />
          </span>
          <div v-if="node?.id.length === 36 && store.state.movePlace && !store.state.childrenIds.includes(node.id)" class="movePlace" @click.prevent="sendId(node, $event)">
            <label :for="node.id"></label>
            <input v-if="store.state.radioButtonId === node.id" type="radio" name="relocate" :id="node.id" checked>
            <input v-else type="radio" name="relocate" :id="node.id">
            <span class="radioMoove"></span>
          </div>
          <!-- <span
            v-else
            class="Tree__item-label"
            @click="goOnClick(node)"
            >{{ node?.name }}</span
          > -->
          <span v-if="isAlert()" class="icon icon__alert">
            <img :src="require('@/assets/alert.svg')" alt="alert" />
          </span>
        </div>
        <span v-if="isFavourite()" class="Tree__item-label-info">Fav</span>
<!--        <span v-if="$store.state.startpage === '/machine/' + node?.id" class="Tree__item-label-info">Start</span>-->
      </div>
      <span class="icon logo-icon" v-if="node?.databaseid?.length !== 36 && !store.state.movePlace">
        <!-- <img v-if="node?.icon === ''" :src="require('@/assets/logo.svg')"/>
        <img v-else class="icon_external_address" :src="node?.icon"/> -->
      </span>
    </div>
    <span v-if="node?.databaseid?.length === 36 && !store.state.movePlace" class="Tree__icon icon__more-wrapper" :style="[(modalActive || qrModalActive) ? {'background-color': '#099F45'} : {}]">

      <modal v-if="this.$store.state.edit" @close="toggleModal" :modalActive="modalActive">
        <div v-if="node.machinekb != 0 && ((store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin')) || node.permissions?.includes('view'))" @click="toggleQRModal();" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/qr_code_icon.svg')" alt="Generate QR Code" />
          <span>Generate QR Code</span>
        </div>
        <div v-if="(store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin')) || node.permissions?.includes('edit')" class="modal-inner-divider" />
        <div v-if="node.machinekb == 0 && ((store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin')) || node.permissions?.includes('edit'))" @click="addLibrary.clickHandler(node); toggleModal()" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/machine.svg')" alt="addLibrary" />
          <span>Add Library<br/> to location</span>
        </div>
        <div v-else-if="(store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin')) || node.permissions?.includes('edit')" @click="delLibrary.clickHandler(node); toggleModal()" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/machinered.svg')" alt="delLibrary" />
          <span style="color: #F44336;">Delete Library<br/> from location</span>
        </div>
        <div class="modal-inner-divider" />
        <div v-if="(store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin')) || node.permissions?.includes('add')" @click="addLocation.clickHandler(node); toggleModal()" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/addMore.svg')" alt="addMore" /><span>Add location</span>
        </div>
        <!-- <div @click="duplicateRecord.clickHandler(node); toggleModal()" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/duplicate.svg')" alt="duplicate" />
          <span>Duplicate</span>
        </div> -->
        <!-- <div @click="moveUp.clickHandler(node); toggleModal()" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/moveUp.svg')" alt="moveUp" />
          <span>Move up</span>
        </div>
        <div @click="moveDown.clickHandler(node); toggleModal()" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/moveDown.svg')" alt="moveDown" />
          <span>Move down</span>
        </div> -->
        <div v-if="node.parentid != '0' && ((store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin')) || node.permissions?.includes('edit'))" @click="duplicateRecord.clickHandler(node); toggleModal()" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/duplicate.svg')" alt="duplicate" />
          <span>Duplicate</span>
        </div>
        <div v-if="node.depth != 0 && ((store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin')) || node.permissions?.includes('edit'))" @click="store.state.moveId = node.id; movePlaceChange(); toggleModal()" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/move.svg')" alt="move" />
          <span>Move</span>
        </div>
        <div v-if="(store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin')) || node.permissions?.includes('edit')" @click="editRecord.clickHandler(node); toggleModal()" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/edit.svg')" alt="edit" />
          <span>Edit</span>
        </div>
        <div v-if="node.parentid != '0' && node.depth != 0 && ((store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin')) || node.permissions?.includes('delete'))" @click="store.state.moveId = node.id; movePlaceChange(); store.state.movePlace = false; deleteRecord.clickHandler(node); toggleModal()" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/delete.svg')" alt="delete" />
          <span>Delete</span>
        </div>
      </modal>

      <modal v-if="!this.$store.state.edit" @close="toggleModal" :modalActive="modalActive">
<!--        <div @click="setAsStart.clickHandler(node); toggleModal()" class="modal-inner-row" style="margin: 0px;">-->
<!--          <img class="modal-icon" :src="require('@/assets/setStart.svg')" alt="setStart" />-->
<!--          <span>Set as start page</span>-->
<!--        </div>-->
<!--        <div v-if="node.machinekb != 0" class="modal-inner-divider" />-->
        <div v-if="node.machinekb != 0" @click="toggleQRModal();" class="modal-inner-row">
          <img class="modal-icon" :src="require('@/assets/qr_code_icon.svg')" alt="Generate QR Code" />
          <span>Generate QR Code</span>
        </div>
      </modal>

      <QRModal v-if="qrModalActive" @close="toggleQRModal" :modalActive="qrModalActive">
        <div class="modal-inner-row">
          <div class="qrcode_block" @click.stop>
            <div class="tag">
              <p>Preview</p>
              <div style="margin-left: auto; height: 150px; display: flex; align-items: center; padding-right: 12px; opacity: .5;">
                <qrcode-vue :value="currentURL + node.route + '/' + node.id" :size="125" render-as="svg" level="H" />
              </div>
              <div style="display: none;"><qrcode-vue :id="'qrcode-image-'+node.id" :value="currentURL + node.route + '/' + node.id" :size="qrcodeSize" render-as="canvas" level="H" /></div>
              <div style="display: none;">
                <div :id="'qrcode-pdf-'+node.id">
                  <div v-for="it in 2" :key="it" class="qr_to_print">
                    <div class="bopus_branding">The Book of Knowledge for Your Business</div>
                    <qrcode-vue :value="currentURL + node.route + '/' + node.id" :size="qrcodeSize" render-as="canvas" level="H" />
                    <h2>{{qrcodeName !== '' ? qrcodeName : node.name}}</h2>
                    <p>Mount Near Machine to Enable Library</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="tag">
              <p>Name</p>
              <input style="text-align: right; padding-right: 16px;" type="text" v-model="qrcodeName" :placeholder="node.name">
            </div>
            <div class="tag">
              <p>Image Size</p>
              <!-- <span class="qrcode_size_title"></span> -->
              <div style="display: flex; justify-content: space-between; flex-direction: row; flex: 1;">
                <div class="qrcode-params-inputs">
                  <span class="qrcode_title">Size</span>
                  <input class="qrcode_input" type="text" v-model="qrcodeSize">
                </div>
                <div class="qrcode-params-inputs">
                  <span class="qrcode_title">Frame size</span>
                  <input class="qrcode_input" type="text" v-model="qrcodeFrameSize">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="qr_button_set">
          <div v-if="saveQRTypeChoose" class="qr_cancel" @click="saveQRTypeChoose = false;">cancel</div>
          <div v-else class="qr_cancel" @click="toggleQRModal">cancel</div>
          <div v-if="saveQRTypeChoose" class="qr_save" @click="saveQRCode(node);">Image</div>
          <div v-else class="qr_save" @click="saveQRTypeChoose = true;">save</div>
          <div v-if="saveQRTypeChoose" class="qr_copy" @click="saveQRCode(node,'pdf')">PDF</div>
          <div v-else :class="'qr_copy ' + {'qr_copied' : QRcopied}" @click="copyQRCode(node)">{{QRcopied ? 'copied' : 'copy'}}</div>
        </div>
      </QRModal>

      <button v-if="(store.state.user.role.includes('admin') || store.state.user.role.includes('bOpus_admin')) || node.permissions?.length > 0" class="icon__button-more" @click="toggleModal" type="button" :style="[modalActive ? { 'background-color': '#099F45', 'zIndex': '16' } : {}]">
        <img v-if="!modalActive" :src="require('@/assets/more.svg')" alt="more" />
        <img v-else :src="require('@/assets/more_white.svg')" alt="more" />
      </button>
    </span>
  </div>

  <div v-if="node.parentid == store.state.radioButtonId && isLastItem" class="move_here tileSubtext">
    <p :class="[{ 'move_here_active': store.state.moveToSelect[0] == node.id && store.state.moveToSelect[1] == 1}]" @click="moveLocationTo(node, 1)">MOVE HERE</p>
  </div>

    <div class="Tree__item-expanded" ref="itemHeight" v-show="expanded">
      <span :style="{
          'margin-left': `${depth * marginAmount}px`,
        }" :class="{
          'vertical-line': expanded,
          'vertical-line__last-item': isLastItem,
        }" />
      <tree-item v-for="(child, idx) in node?.children" :key="child.name" :isEditMode="isEditMode" :node="child"
        :depth="depth + 1" :isLastItem="idx + 1 === node?.children.length"
        :setAsStart="{ clickHandler: setAsStart.clickHandler }" :addFavourite="{ clickHandler: addFavourite.clickHandler }"
        :createSos="{ clickHandler: createSos.clickHandler }" :addLocation="{ clickHandler: addLocation.clickHandler }"
        :addLibrary="{ clickHandler: addLibrary.clickHandler }"
        :delLibrary="{ clickHandler: delLibrary.clickHandler }" :moveUp="{ clickHandler: moveUp.clickHandler }"
        :moveDown="{ clickHandler: moveDown.clickHandler }" :editRecord="{ clickHandler: editRecord.clickHandler }"
        :duplicateRecord="{ clickHandler: duplicateRecord.clickHandler }"
        :deleteRecord="{ clickHandler: deleteRecord.clickHandler }" :ref="
          (item) => {
            treeChild[idx] = item;
          }
        " @onClick="onNodeClick" @onMoveClick="onMoveClicked" />
    </div>

  <div class="delete_section" @click.stop v-if="deleteEmpty">
    <h2>Deletion Warning</h2>
    <div class="trash_can"></div>
    <div class="buttons">
      <btn class="button secondary" v-text="'cancel'" @click="deleteEmpty = !deleteEmpty"></btn>
      <btn class="button" v-text="'confirm delition'" @click="deleteEmptySection"></btn>
    </div>
  </div>
</template>

<script>
import {
  computed, nextTick, ref, toRefs,
} from 'vue';
import { useStore } from 'vuex';
import btn from '../buttons/button.vue';
import Modal from './Modal.vue';
import QRModal from './QRModal.vue';
import QrcodeVue from 'qrcode.vue';
import html2pdf from 'html2pdf.js';
import {SuperTokensController as STC} from "@/services/SuperTokensController";

export default {
  name: 'TreeItem',
  components: { Modal, QRModal, btn, QrcodeVue },
  methods: {},
  props: {
    node: Object,
    depth: {
      type: Number,
      default: 0,
    },
    isLastItem: {
      type: Boolean,
      default: true,
    },
    isFirstItem: {
      type: Boolean,
      default: false,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    setAsStart: Object,
    addFavourite: Object,
    createSos: Object,
    addLocation: Object,
    delLibrary: Object,
    addLibrary: Object,
    moveUp: Object,
    moveDown: Object,
    editRecord: Object,
    duplicateRecord: Object,
    deleteRecord: Object,
  },

  emits: ['onClick', 'onMoveClick'],

  setup(props, { emit }) {
    const { node } = toRefs(props);
    const store = useStore();

    const QRcopied = ref(false);
    const modalActive = ref(false);
    const qrModalActive = ref(false);
    const saveQRTypeChoose = ref(false);
    const itemHeight = ref(0);

    const expanded = ref(false);
    let exposedTreeNodes = localStorage.getItem('exposedTreeNodes') !== null ? JSON.parse(localStorage.getItem('exposedTreeNodes')) : [];
    if (exposedTreeNodes.length > 0 && exposedTreeNodes.includes(node.value?.id)) {
      expanded.value = true;
    } else if (node.value?.id === undefined) {
      expanded.value = true;
    }
    const currentURL = window.location.origin;

    const treeChild = ref([]);

    let qrcodeName = ref('');
    const qrcodeSize = ref(275);
    const qrcodeFrameSize = ref(20);

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
      qrcodeName.value = '';
    };

    const toggleQRModal = () => {
      qrModalActive.value = !qrModalActive.value;
      modalActive.value = !modalActive.value;
    };
    const saveQRCode = (node, type = 'image') => {
      if (type === 'pdf') {
        html2pdf(document.getElementById('qrcode-pdf-'+node.id));
        return;
      }

      let image = getImageWithFrame(document.getElementById('qrcode-image-'+node.id), qrcodeFrameSize.value/*, '#0a9f45'*/);
      if (!image) {
        console.log('no canvas');
        return;
      }
      let a = document.createElement('a');
      a.href = image;
      a.download = (qrcodeName.value !== '' ? qrcodeName.value : node.name) + '.jpeg';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    const copyQRCode = (node) => {
      document.getElementById('qrcode-image-'+node.id).toBlob(blob => navigator.clipboard.write([new ClipboardItem({'image/png': blob})]));
      QRcopied.value = true;
      setTimeout(() => QRcopied.value = false, 2000);
    };
    const getImageWithFrame = (canvasElement, frameSize, frameColor = '#ffffff') => {
      if (canvasElement === undefined) {
        return false;
      }
      let newCanvas = document.createElement('canvas');
      let newContext = newCanvas.getContext('2d');
      newCanvas.width = canvasElement.width + frameSize * 2;
      newCanvas.height = canvasElement.height + frameSize * 2;
      newContext.fillStyle = frameColor;
      newContext.fillRect(0, 0, newCanvas.width, newCanvas.height);
      newContext.drawImage(canvasElement, frameSize, frameSize);

      return newCanvas.toDataURL("image/jpeg", 1.0);//.toDataURL("image/png").replace("image/png", "image/octet-stream");
    };
    const hasChildren = () => node.value?.children;
    const isHome = () => node.value?.isHome;
    const isFavourite = () => node.value?.isFavourite;
    const isAlert = () => node.value?.isAlert;

    const onNodeClick = (nodeData) => {
      emit('onClick', nodeData);
    };
    const onMoveClicked = (nodeData) => {
      emit('onMoveClick', nodeData);
    };
    const nodeClicked = () => {
      emit('onClick', !hasChildren ? undefined : node.value);
    };
    const movePlaceChange = () => {
      if (!store.state.movePlace) {
        nodeClickedForExpand(false);
        emit('onMoveClick', node.value);
      }
      store.state.movePlace = !store.state.movePlace;
    };
    // const here = (node) => {
    //   console.log(node.parentId)
    // };
    const sendId = (node) => {
      if (store.state.radioButtonId === null) {
        store.state.radioButtonId = node.id;
        expanded.value = true;
      } else {
        store.state.radioButtonId = null;
        expanded.value = false;
      }
      store.state.moveToSelect = [];
    };
    const moveLocationTo = (node, pos) => {
      if (pos == 0) {
        store.state.moveToSelect = [node.id, pos]
      } else if (pos == 1) {
        store.state.moveToSelect = [node.id, pos]
      } else {
        console.log('err')
      }
    };
    const nodeClickedForExpand = (expand = null) => {
      if (expand === true) {
        expanded.value = true;
      } else if (expand === false) {
        expanded.value = false;
      } else {
        expanded.value = !expanded.value;
      }
      let exposedTreeNodes = localStorage.getItem('exposedTreeNodes') !== null ? JSON.parse(localStorage.getItem('exposedTreeNodes')) : [];
      if (expanded.value && node.value?.id !== undefined && (exposedTreeNodes.length === 0 || !exposedTreeNodes.includes(node.value?.id))) {
        exposedTreeNodes.push(node.value?.id);
      } else if (!expanded.value && node.value?.id !== undefined && (exposedTreeNodes.length > 0 || exposedTreeNodes.includes(node.value?.id))) {
        exposedTreeNodes.splice(exposedTreeNodes.indexOf(node.value?.id),1);
      }
      localStorage.setItem('exposedTreeNodes', JSON.stringify(exposedTreeNodes));
      STC.setCurrentUserMetadata({"exposedTreeNodes": exposedTreeNodes});
      // fetch('https://auth.wave7.cloud/auth/setmetadata', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type':'application/json',
      //     'Accept':'application/json'
      //   },
      //   body: JSON.stringify({"exposedTreeNodes": exposedTreeNodes})
      // }).then((res) => res.json()).then((r) => {
      //   console.log("SET-METADATA-RESPONSE",r);
      // }).catch((error) => {
      //   console.log('Looks like there was a problem: \n', error);
      // });
    };

    // equipHomeClicked() {
    //   console.log('worked');
    //   // this.expanded = !this.expanded;
    //   // this.$emit('onClick', !this.hasChildren() ? this.node : undefined);
    //   const data = this.node;
    //   if(data) {
    //     // alert(data.route);
    //     if(data.route) {
    //       alert();
    //       this.$router.push({ path: `${data.route}/${data.id}` });
    //     }
    //     else {
    //       this.$router.push({ path: data.route });
    //     }
    //   }
    // },

    const collapse = () => {
      expanded.value = true;

      if (treeChild.value?.length > 0) {
        treeChild.value.forEach((refItem) => {
          refItem.collapse();
        });
      }
    };
    const expand = async () => {
      expanded.value = false;
      await nextTick();
      if (treeChild.value?.length > 0) {
        treeChild.value.forEach((refItem) => {
          refItem.expand();
        });
      }
    };

    return {
      modalActive,
      qrModalActive,
      saveQRTypeChoose,
      itemHeight,
      expanded,
      treeChild,
      currentURL,
      qrcodeName,
      qrcodeSize,
      qrcodeFrameSize,
      marginAmount: computed(() => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        if (windowWidth < windowHeight && windowWidth < 600) {
          return 10;
        }
        if (windowWidth > windowHeight && windowHeight < 600) {
          return 10;
        }
        return 10;
      }),
      collapse,
      expand,
      onNodeClick,
      onMoveClicked,
      nodeClicked,
      nodeClickedForExpand,
      toggleModal,
      toggleQRModal,
      saveQRCode,
      copyQRCode,
      QRcopied,
      getImageWithFrame,
      hasChildren,
      isHome,
      isFavourite,
      isAlert,
      movePlaceChange,
      store,
      // here,
      sendId,
      moveLocationTo,
    };
  },
};
</script>

<style lang="scss">
body{
  transition: all 0.3s ease;
}
.qr_to_print{
  border: 1px solid #787878;
  margin: 50px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  background-image: url(../../../public/img/wave7.png);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 90px;
  position: relative;
  .bopus_branding{
    height: 40px;
    line-height: 42px;
    padding-left: 80px;
    background-image: url(../../../public/img/bopus/bopus.svg);
    background-size: 69px;
    background-repeat: no-repeat;
    background-position: center left;
    margin-bottom: 20px;
  }
  canvas{
    margin: 0 auto;
    width: 275px !important;
    height: 275px !important;
  }
  h2{
    color: #444;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0px;
  }
  p{
    font-size: 0.65rem;
    line-height: 0.8rem;
    position: absolute;
    bottom: 0px;
    left: 3px;
    margin: 0px;
    color: #787878;
  }
}
.test-class-for-pdf {
  color: #ff0000;
}
.qrcode-params-inputs {
  position: relative;
  display: flex;
  width: calc(50% - 5px);
}
.qr_button_set{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 32px;
  margin-top: auto;
  border-top: 2px solid #2525251A;
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    margin-top: 32px;
    margin-bottom: 32px;
  }
  >div{
    height: 45px;
    line-height: 45px;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
  }
  .qr_save,.qr_copy{
    background-color: #d9e9e2;
    color: #444;
    width: calc(50% - 8px);
    transition: all .2s ease-in-out;
  }
  .qr_copy{
    margin-left: auto;
  }
  .qr_copied{
   transform: scale(110%);
  }
  .qr_cancel{
    border: 1px solid;
    width: 100%;
    border-color: #787878;
    color: #787878;
    background-color: #fff;
    margin-bottom: 16px;
    &:hover{
      border-color: #006C3E;
      color: #006C3E;
      background-color: #fff;
    }
    &:active{
      border-color: #004D2C;
      background-color: #004D2C;
      color: #fff;
    }
  }
}
.qrcode_block {
  cursor: default;
  background-color: #fff;
  box-sizing: border-box;
  transition: bottom 0.3s;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.qrcode_block .tag {
  display: flex;
  margin-top: 30px;
  position: relative;
  align-items: center;
  .qrcode_title{
    position: absolute;
    height: 21px;
    width: 100%;
    display: block;
    background-color: #BCBCBC;
    padding: 0px;
    border-radius: 8px 8px 0 0;
    color: #444;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
  }
  .qrcode_title::after{
    content: 'px';
    display: block;
    position: absolute;
    height: 42px;
    right: 8px;
    bottom: -49px;
  }
  .qrcode_input{
    height: 58px;
    width: calc(50% - 5px);//125px;
    max-width: 150px;
    padding-top: 21px;
    text-align: right;
    padding-right: 30px;
    color: #444;
  }
  input{
    font-size: 1rem;
    flex: 1;
    box-sizing: border-box;
    outline: none;
    height: 42px;
    color: #787878;
    background-color: rgba(120, 120, 120, 0.05);
    border: 0px;
    width: 100%;
    padding-left: 16px;
    box-sizing: border-box;
    border-radius: 8px;
  }
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    input,p{
      height: 38px;
    }
  }
}
.qrcode_block .tag:first-child {
  margin-top: 0px;
}
.qrcode_block .tag > p {
  width: 120px;
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  line-height: 21px;
  color: #787878;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.move_here{
  text-align: center;
  p{
    display: inline-block;
    background-color: #BCBCBC;
    margin: 0 auto;
    // border-radius: 10px;
    // height: 20px;
    line-height: 20px;
    // padding: 0 5px;
    font-size: 1rem;
    color: #fff;
    width: calc(100% - 20px);
    // max-width: 298px;
    margin-left: 20px;
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   height: 64px;
    //   line-height: 64px;
    // }
    // DESKTOP
    // @media screen and (min-width: 1025px) and (max-width: 27 00px){
    @media screen and (min-width: 1025px){
      height: 49px;
      line-height: 49px;
    }
    // LAPTOP
    @media screen and (min-width: 901px) and (max-width: 1024px){
      height: 39px;
      line-height: 39px;
    }
    // TABLET
    @media screen and (min-width: 481px) and (max-width: 900px){
      height: 33px;
      line-height: 33px;
    }
    // MOBILE
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      height: 32px;
      line-height: 32px;
    }
  }
  .move_here_active{
    background-color: #099F45;
  }
}
.movePlace {
  position: relative;
  height:  1.63rem;
  width:  1.63rem;
  label{
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
  input[type="radio"]{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .radioMoove{
    display: block;
    height: 100%;
    width: 100%;
    background-image: url(../../../public/img/select/radio-off.svg);
    background-repeat: no-repeat;
    background-size: 100%;
  }
  input[type="radio"]:checked ~ .radioMoove{
    background-image: url(../../../public/img/select/radio-on.svg);
  }
}

.Tree {
  &__item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    padding: 2px 0;
    position: relative;
    @media screen and (min-width: 901px) and (max-width: 1024px){
      padding: 4px 0;
    }
    // DESKTOP
    // @media screen and (min-width: 1025px) and (max-width: 27 00px){
    @media screen and (min-width: 1025px){
      padding: 3px 0;
    }
  }

  &__item-expanded {
    position: relative;
  }

  &__item-inner {
    background-color:#7878780D;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    font-weight: 400;
    color: #444444;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-left: 1px solid #444444;
    padding-left: 8px;
    padding-right: .333rem;
    @media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1370px) and (min-height: 930px) and (orientation: portrait){
    }
    @media screen and (max-height: 1024px) and (min-height: 600px) and (max-width: 1366px) and (min-width: 961px) and (orientation: landscape) {
    }
    @media screen and (max-width: 599px) and (orientation: portrait) {
    }
    @media screen and (max-height: 599px) and (orientation: landscape) {
      height: 32px;
    }
  }

  &__item-label-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-radius: 4px;
    width: 100%;
    overflow: hidden;
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   min-height: 64px;
    // }
    // DESKTOP
    // @media screen and (min-width: 1025px) and (max-width: 27 00px){
    @media screen and (min-width: 1025px){
      min-height: 49px;
    }
    // LAPTOP
    @media screen and (min-width: 901px) and (max-width: 1024px){
      min-height: 39px;
    }
    // TABLET
    @media screen and (min-width: 481px) and (max-width: 900px){
      min-height: 33px;
    }
    // MOBILE
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      min-height: 32px;
    }
  }

  &__item-label-inner {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    overflow: hidden;
  }

  &__item-label {
    width: 100%;
  }

  &__item-label {
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  &__item-label-info {
    font-size: 0.75rem;
    font-weight: 500;
    color: #bcbcbc;
  }
}

.Tree .edit_input {
  font-size: 1rem;
}

.icon_external_address {
  width: 22px;
  height: 22px;
}
.modal-icon {
  height: 24px;
  flex-shrink: 0;
  margin-right: 12px;
  align-self: center;
  width: 2rem;
    // LAPTOP
  @media screen and (min-width: 901px) and (max-width: 1024px){
    height: 100%;
  }
    // TABLET
  @media screen and (min-width: 481px) and (max-width: 900px){
    height: 100%;
  }
  // MOBILE
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    height: 100%;
  }
  img{
    height: 100%;
  }
}
.icon {
  height: 24px;
  flex-shrink: 0;
  margin-right: 12px;
  align-self: center;
  // width: 2rem;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   height: 39px;
  // }
  // DESKTOP
  // @media screen and (min-width: 1025px) and (max-width: 27 00px){
  @media screen and (min-width: 1025px){
    height: 26px;
  }
    // LAPTOP
  @media screen and (min-width: 901px) and (max-width: 1024px){
    height: 26px;
  }
    // TABLET
  @media screen and (min-width: 481px) and (max-width: 900px){
    height: 24px;
  }
  // MOBILE
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    height: 24px;
  }
  img{
    height: 100%;
  }
  &__alert {
    margin-left: 8px;
  }

  &__more-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 32px;
    flex-grow: 1;
    margin-right: 0px;
    margin-left: 4px;
    border-radius: 4px;
    box-sizing: border-box;
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   height: 64px;
    //   width: 64px;
    // }
    // DESKTOP
    // @media screen and (min-width: 1025px) and (max-width: 27 00px){
    @media screen and (min-width: 1025px){
      height: 49px;
      width: 49px;
    }
    // LAPTOP
    @media screen and (min-width: 901px) and (max-width: 1024px){
      height: 39px;
      width: 39px;
      margin-left: 8px;
    }
    // TABLET
    @media screen and (min-width: 481px) and (max-width: 900px){
      height: 33px;
      width: 33px;
    }
    // MOBILE
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      height: 32px;
      width: 32px;
    }
  }

  &__button-more {
    padding-inline-end: 0;
    padding-inline-start: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 32px;
    height: 100%;
    // flex-shrink: 0;
    border-radius: 4px;
    background-color: rgba(120, 120, 120, 0.05);
    outline: none;
    border: none;
    
    // @media screen and (min-width: 27 01px) and (max-width: 3840px){
    //   width: 64px;
    // }
    // DESKTOP
    // @media screen and (min-width: 1025px) and (max-width: 27 00px){
    @media screen and (min-width: 1025px){
      width: 49px;
    }
    // LAPTOP
    @media screen and (min-width: 901px) and (max-width: 1024px){
      width: 39px;
    }
    // TABLET
    @media screen and (min-width: 481px) and (max-width: 900px){
      width: 33px;
    }
    // MOBILE
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      width: 32px;
    }
    img{
      height: 80%;
      width: 100%;
    }
  }
}
.logo-icon{
  height: 25px;
  width: 25px;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //     margin-right: 5px;
  //     width: 39px;
  //     height: 39px;
  //   }
    // DESKTOP
    // @media screen and (min-width: 1025px) and (max-width: 27 00px){
    @media screen and (min-width: 1025px){
      margin-right: 3px;
      width: 29px;
      height: 29px;
    }
    // LAPTOP
    @media screen and (min-width: 901px) and (max-width: 1024px){
      margin-right: 1px;
      width: 27px;
      height: 27px;
    }
    // TABLET
    @media screen and (min-width: 481px) and (max-width: 900px){
      margin-right: -2px;
    }
    // MOBILE
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      padding-right: 0px;
      margin-right: -2px;
    }
}

.modal-inner-row {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
  line-height: 1.238rem;
  font-size: 1rem;
  width: 100%;
  cursor: pointer;
  span{
    padding: 0.571rem 0;
    line-height: 1.238rem;
    font-size: 1rem;
  }
}

.modal-inner-divider {
  width: 100%;
  height: 3px;
  border-radius: 1.5px;
  background-color: $gray;
  opacity: 0.3;
  margin: 2px 0;
}

.vertical-line {
  width: 1px;
  // background-color: $gray;
  left: 12px;
  position: absolute;
  bottom: 19px;
  top: 0;

  &__last-item {
    &:before {
      content: "";
      position: absolute;
      width: 1px;
      top: -19px;
      left: -37px;
      bottom: 0;
      // background: $white;
    }
  }
}

.gorizontal-line {
  // background-color: $gray;
  width: 100%;
  position: absolute;
  height: 1px;
  left: -25px;
  max-width: 16px;

  &__item-without-child {
    max-width: 44px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 540px) and (max-height: 1368px) and (min-height: 720px) and (orientation: portrait) {}

@media screen and (max-width: 599px) and (orientation: portrait),
screen and (max-height: 500px) and (orientation: landscape) {
  .gorizontal-line {
    max-width: 8px;
    left: -9px;

    @media screen and (max-width: 599px) and (orientation: portrait) {
      max-width: 25px;
    }

    @media screen and (max-height: 599px) and (orientation: landscape) {
      max-width: 25px;
    }
  }
  .modal-inner-row{
    img{
      margin-right: 8px;
    }
    span{
      // font-size: 17px;
      // line-height: 24px;
      font-weight: 400;
    }
  }
  .icon {
    // margin-right: 0px;

    &__more-wrapper {
      margin-left: 4px;
    }
  }

  .Tree {
    &__item {
      padding: 2px 0;
    }

    // &__item-label {
    //   font-size: 12px;
    //   padding-left: 4px;
    // }

    // &__item-label-info {
    //   font-size: 12px;
    // }
  }
}

// .on-expand-enter-active,
// .on-expand-leave-active {
//   transition: all 0.3s ease;
// }

// .on-expand-enter-from,
// .on-expand-leave-to {
//   transform: translateY(-100%);
// }
</style>
